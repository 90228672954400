import {
  Box,
  Button,
  Icon as ChakraIcon,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import { sanitize } from 'dompurify';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { closeOutlined } from '@lon/shared/assets';
import {
  FormTextarea,
  Icon,
  ReactDatePicker,
  RequiredNotification,
} from '@lon/shared/components';
import { dateFormats, sxLightScrollBar } from '@lon/shared/constants';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useReturnAssignmentMutation } from '@lon/shared/requests';
import { formatDate, getValidation, types } from './duck';
import {
  AllIneligibleMessage,
  IneligibleStudentsMessage,
  IntroMessage,
} from './components';
import styles from './ReturnAssignmentModal.module.css';

const ReturnAssignmentModal: React.FC<types.ReturnAssignmentModalProps> = ({
  onClose,
  isOpen,
  isMultiple = false,
  studentsData,
  onCompleted,
}) => {
  const { t } = useTranslation();

  const [fetch, { loading }] = useReturnAssignmentMutation();
  const toast = useToast();

  const { eligibleStudents, ineligibleStudents } = studentsData?.reduce(
    (acc, assignment) => {
      const isToBeGraded =
        assignment.transformedStatus ===
        StudentAssignmentStatusTransformed.ToBeGraded;
      const isGraded =
        assignment.transformedStatus ===
        StudentAssignmentStatusTransformed.Graded;

      const accKey =
        isMultiple && !isToBeGraded && !isGraded
          ? 'ineligibleStudents'
          : 'eligibleStudents';

      return {
        ...acc,
        [accKey]: [...acc[accKey], assignment],
      };
    },
    {
      eligibleStudents: [] as types.Student[],
      ineligibleStudents: [] as types.Student[],
    }
  ) || { eligibleStudents: [], ineligibleStudents: [] };

  const hasIneligibleStudents = ineligibleStudents.length;
  const allStudentsAreIneligible =
    hasIneligibleStudents && ineligibleStudents.length === studentsData.length;
  const someStudentsAreIneligible =
    hasIneligibleStudents && ineligibleStudents.length !== studentsData.length;
  const assignmentsWithPassedDueDate = eligibleStudents.filter((assignment) => {
    const endDate = assignment.time?.endDate;
    return (
      endDate &&
      parseISO(endDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    );
  });
  const showDatePicker = assignmentsWithPassedDueDate.some(
    (assignment) => !assignment.lateTurnIn
  );

  const { validationSchema, defaultValues } = getValidation(showDatePicker);
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onSubmit',
  });

  const onCloseModal = () => {
    formProviderProps.reset();
    onClose();
  };

  const handleSubmit = async (values: {
    notes: string;
    endDate?: Date | null;
  }) => {
    const payloads = studentsData.reduce<
      {
        assignmentId: string;
        studentIds: string[];
        notes: string;
        endDate: string | null;
      }[]
    >((acc, val) => {
      if (
        val.transformedStatus !== StudentAssignmentStatusTransformed.Graded &&
        val.transformedStatus !== StudentAssignmentStatusTransformed.ToBeGraded
      ) {
        return acc;
      }
      const existingPayload = acc.find(
        (item) => item.assignmentId === val.assignmentId
      );

      if (existingPayload) {
        existingPayload.studentIds.push(val.studentId as string);
      } else {
        acc.push({
          assignmentId: val.assignmentId,
          studentIds: [val.studentId as string],
          notes: values.notes,
          endDate: values.endDate ? formatDate(values.endDate) : null,
        });
      }

      return acc;
    }, []);

    try {
      const result = await Promise.all(
        payloads.map((payload) => {
          return fetch({
            variables: { sessionsParams: payload },
          });
        })
      );
      const areAllSuccessfull = result.every(
        (res) => res?.data?.returnAssignment?.success
      );
      const areSomeSuccessfull = result.some(
        (res) => res?.data?.returnAssignment?.success
      );

      const successToast = (eligibleStudentsAmount: number) => {
        toast({
          title: t('assignments.returnSuccessToastTitle'),
          description: (
            <Text whiteSpace="pre-wrap">
              {isMultiple
                ? t('assignments.returnSuccessToastDescriptionMultiple', {
                    amount: eligibleStudentsAmount,
                  })
                : sanitize(
                    t('assignments.returnSuccessToastDescription', {
                      name: `${studentsData[0]?.firstName} ${studentsData[0]?.lastName}`,
                      interpolation: {
                        escapeValue: false,
                      },
                    })
                  )}
            </Text>
          ),
          variant: 'success-light',
          status: 'success',
          isClosable: true,
          duration: 3000,
        });
      };

      if (areAllSuccessfull) {
        const eligibleStudentsAmount = payloads.reduce((acc, val) => {
          return acc + (val?.studentIds?.length || 0);
        }, 0);
        successToast(eligibleStudentsAmount);
        onCompleted && onCompleted();
        onCloseModal();
        return;
      } else if (areSomeSuccessfull) {
        result.forEach((item, index) => {
          if (item.data?.returnAssignment?.success) {
            successToast(payloads[index]?.studentIds?.length);
          }
        });
        onCompleted && onCompleted();
        onCloseModal();
        throw new Error();
      } else {
        throw new Error();
      }
    } catch (e) {
      toast({
        title: t('systemMessages.038'),
        status: 'error',
        variant: 'error-light',
        isClosable: true,
        duration: null,
      });
    }
  };

  return (
    <Modal
      variant="rounded"
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        data-focus-visible-disabled
        containerProps={{ css: sxLightScrollBar }}
      >
        <FormProvider {...formProviderProps}>
          <Flex
            as="form"
            className="emotion-css-cache userInputForm"
            direction="column"
            overflow="hidden"
            id="datepicker-portal"
            noValidate
            onSubmit={formProviderProps.handleSubmit(handleSubmit)}
          >
            <ModalHeader>
              <Text as="h5" variant="formHeader">
                {t('assignments.returnAssignment')}
              </Text>
              <IconButton
                aria-label={t('reopenAssignmentModal.closeModal')}
                size="md"
                variant="inverted"
                onClick={onCloseModal}
                icon={<ChakraIcon as={closeOutlined} w="24px" h="24px" />}
              />
            </ModalHeader>
            <Box boxShadow="0px 2px 7px 0px #2B36461A" py={2} px={8}>
              <Text variant="n1">
                {isMultiple
                  ? t('assignments.toMultipleStudents')
                  : sanitize(
                      t('assignments.toSingleStudent', {
                        name: `${studentsData[0].firstName} ${studentsData[0].lastName}`,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
              </Text>
            </Box>
            <ModalBody
              css={sxLightScrollBar}
              py={8}
              sx={{
                label: {
                  mb: 2,
                },
              }}
            >
              {!allStudentsAreIneligible && (
                <IntroMessage
                  isMultiple={isMultiple}
                  studentAssignments={eligibleStudents}
                />
              )}
              {!!someStudentsAreIneligible && (
                <IneligibleStudentsMessage
                  ineligibleStudents={ineligibleStudents}
                />
              )}
              {!!allStudentsAreIneligible && <AllIneligibleMessage />}
              {!allStudentsAreIneligible && (
                <>
                  {showDatePicker && (
                    <>
                      <RequiredNotification />
                      <ReactDatePicker
                        isRequired
                        showCurrentDatePicker
                        name="endDate"
                        placeholder={t('assignments.dateFormat')}
                        calendarClassName={styles['react-datepicker']}
                        popperPlacement="bottom-end"
                        dateFormat={dateFormats.monthDayYear}
                        minDate={new Date()}
                        portalId="datepicker-portal"
                        label={
                          <Text
                            as="span"
                            title={t('assignments.endDateLabel')}
                            isTruncated
                          >
                            {t('assignments.endDateLabel')}
                          </Text>
                        }
                        inputLayoutProps={{
                          formLabelProps: {
                            display: 'flex',
                            overflow: 'hidden',
                          },
                          my: 4,
                        }}
                      />
                    </>
                  )}
                  <FormTextarea
                    placeholder={t(
                      `assignments.${
                        isMultiple
                          ? 'studentNoteGroupPlaceholder'
                          : 'studentNotePlaceholder'
                      }`
                    )}
                    id="student-notes"
                    name="notes"
                    borderColor="primary.200"
                    height="148px"
                    label={
                      <>
                        <Text
                          variant="n1"
                          textAlign="left"
                          color="primary.800"
                          {...(isMultiple && { mb: '2' })}
                        >
                          {t('assignments.studentNoteTitle')}
                        </Text>
                        {isMultiple && (
                          <Text variant="s3" color="primary.300">
                            {t(
                              `assignments.${
                                someStudentsAreIneligible
                                  ? 'multiplePartialReturnNotice'
                                  : 'multipleReturnNotice'
                              }`
                            )}
                          </Text>
                        )}
                      </>
                    }
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <HStack justify="space-between" width="100%">
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onCloseModal}
                  isDisabled={loading}
                >
                  {t('assignments.cancelButton')}
                </Button>
                <Button
                  type="submit"
                  leftIcon={<Icon name="check-simple-outlined" size="lg" />}
                  isLoading={loading}
                  variant="solid"
                  isDisabled={loading || !!allStudentsAreIneligible}
                >
                  {t('assignments.returnButton')}
                </Button>
              </HStack>
            </ModalFooter>
          </Flex>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default ReturnAssignmentModal;
