import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon, Select } from '@lon/shared/components';
import { LevelEnumType } from '@lon/shared/requests';

interface DrawerProps {
  handleClose: () => void;
  isOpen: boolean;
}

interface FormValues {
  level: Record<string, string>;
}

const Drawer = ({ handleClose, isOpen }: DrawerProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const level = searchParams.get('level') || '';

  const options = Object.keys(LevelEnumType).map((key) => ({
    label: key.replace(/(\D)(\d)/, '$1 $2'),
    value: key,
  }));

  const defaultValues = {
    level: { label: level.replace(/(\D)(\d)/, '$1 $2'), value: level },
  };

  const formProviderProps = useForm<FormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  const onReset = () => {
    searchParams.delete('level');
    setSearchParams(searchParams);
    handleClose();
  };

  const onSubmit = (values: FormValues) => {
    const level = values?.level?.value;

    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, level });
    handleClose();
  };

  useEffect(() => {
    return () => {
      if (!isOpen) {
        formProviderProps.reset();
      }
    };
  }, [isOpen]);

  return (
    <ChakraDrawer variant="formDrawer" isOpen={isOpen} onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent maxW="62.5rem">
        <DrawerHeader
          display="flex"
          alignItems="center"
          gap={4}
          px={{ xs: 4, b375: 6, md: 8 }}
        >
          <Text variant="h4" as="h2" flexGrow="2" noOfLines={1}>
            {t('reportsDashboard.filters')}
          </Text>
          <DrawerCloseButton top="initial" position="initial" />
        </DrawerHeader>
        <DrawerBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={7}
        >
          <FormProvider {...formProviderProps}>
            <Flex
              as="form"
              onSubmit={formProviderProps.handleSubmit(onSubmit)}
              id="filters"
              w="full"
              justifyContent="center"
            >
              <SimpleGrid columns={2} spacing={6} maxW="764px" px={8} w="full">
                <Select
                  label={t('reportsDashboard.standardsLevel')}
                  name="level"
                  placeholder="Select Standard"
                  defaultValue={defaultValues.level}
                  onChange={(e) => {
                    if (e) {
                      formProviderProps.setValue('level', e, {
                        shouldDirty: true,
                      });
                    }
                  }}
                  options={options}
                />
              </SimpleGrid>
            </Flex>
          </FormProvider>
        </DrawerBody>

        <DrawerFooter justifyContent="space-between" gap="4">
          <Flex gap="4">
            <Button
              leftIcon={<Icon name="chevron-left" />}
              onClick={handleClose}
              aria-label={t('reportsDashboard.backToReport')}
            >
              <Text as="span" isTruncated>
                {t('reportsDashboard.backToReport')}
              </Text>
            </Button>
            <Button
              onClick={onReset}
              aria-label={t('reportsDashboard.clearAll')}
            >
              <Text as="span" isTruncated>
                {t('reportsDashboard.clearAll')}
              </Text>
            </Button>
          </Flex>
          <Button
            variant="solid"
            aria-label={t('reportsDashboard.apply')}
            type="submit"
            form="filters"
            isDisabled={!formProviderProps.formState.isDirty}
          >
            <Text as="span" isTruncated>
              {t('reportsDashboard.apply')}
            </Text>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
