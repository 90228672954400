import { HStack, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Select } from '@lon/shared/components';
import { ClassesContext } from '@lon/shared/contexts';

const ClassesFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const { data, loading } = useContext(ClassesContext);

  const cls = searchParams.get('class') || '';

  const handleChange = (cls: string | undefined) => {
    if (!cls) {
      searchParams.delete('class');
    }
    const params = Object.fromEntries(searchParams.entries());

    setSearchParams({ ...params, ...(cls ? { class: cls } : {}) });
  };

  const options = data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const value = options?.find((item) => item.value === cls);

  return (
    <HStack spacing={4} align="center" alignSelf="normal" flexBasis="50%">
      <Text variant="n3" color="primary.800" id="classes-label">
        {t('reportsDashboard.class')}
      </Text>
      <Select
        formControlProps={{ maxW: '300px' }}
        menuPortalTarget={document.body}
        size="s"
        aria-labelledby="classes-label"
        placeholder=" "
        options={options}
        value={value}
        onChange={(item) => {
          handleChange(item?.value);
        }}
        isDisabled={loading}
      />
    </HStack>
  );
};

export default ClassesFilter;
