import { useLazyQuery } from '@apollo/client';
import { Flex, Spinner } from '@chakra-ui/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Table } from 'libs/shared/modules/table/src';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LevelEnumType, useGetStandardsItemsQuery } from '@lon/shared/requests';
import { parseJSON } from '@lon/shared/utils';
import {
  GET_STANDARD_REPORT,
  StandardReportResponse,
  getColumns,
  getEnumKeyFromNumber,
  getOrder,
  isValidEnumKey,
  mapStandardToStudent,
  prepareData,
  sortStandardsAlphaNumerically,
} from './duck';
import { ReponseObject, StandardRecord } from './duck/types';
import { Caption, Pagination } from './components';

interface ReportProps {
  assignmentId?: string;
  teacherId?: string;
  schoolId?: string;
  districtId?: string;
}

const standardsPerPage = 10;
const itemsPerPage = 20;

const Report: React.FC<ReportProps> = ({
  assignmentId,
  teacherId,
  schoolId,
  districtId,
}) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [tokenCache, setTokenCache] = useState<Record<string, any>>({});
  const [currentToken, setCurrentToken] = useState<string>('');
  const firstElementRef = useRef<{ VarCharValue: string }[]>();

  const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const standardsPage = Number(searchParams.get('standards-page')) || 1;
  const classFilter = searchParams.get('class');
  const levelKey = searchParams.get('level') || undefined;

  useGetStandardsItemsQuery({
    variables: { filter: { teacherIds: [teacherId as string], assignmentId } },
    skip: !teacherId,
    onCompleted(data) {
      if (!data?.ssStandardsItems || !data?.ssStandardsItems?.length) {
        setSearchParams({ ...searchParams, level: 'Level5' });
      } else {
        const levels = data.ssStandardsItems
          .map((item) => item?.standard?.split('.'))
          .sort((a, b) => (b?.length || 0) - (a?.length || 0));
        const lowestLevel = levels[0]?.length;
        setSearchParams({
          ...searchParams,
          level: getEnumKeyFromNumber(lowestLevel || 5),
        });
      }
    },
  });

  const level =
    levelKey && isValidEnumKey(levelKey) ? LevelEnumType[levelKey] : undefined;

  const [fetch, { loading }] =
    useLazyQuery<StandardReportResponse>(GET_STANDARD_REPORT);

  const cacheTokenData = (
    token: string,
    result: any,
    nextToken: string,
    prevToken: string
  ) => {
    setTokenCache((prevCache) => ({
      ...prevCache,
      [token || 'initial']: {
        data: result,
        nextToken,
        prevToken,
      },
    }));
    setCurrentToken(token);
  };

  useEffect(() => {
    if (!level) {
      return;
    }

    const order = getOrder(sorting);
    fetch({
      fetchPolicy: 'cache-and-network',
      variables: {
        schoolId,
        districtId,
        assignmentId,
        level,
        order,
        itemsPerPage,
        teacherId,
        classId: classFilter,
      },
      onCompleted(data) {
        const response: ReponseObject = parseJSON(
          data?.ssStandards?.resultSetJson || '[]'
        );
        firstElementRef.current = response?.Rows?.shift()?.Data;
        const preparedData = prepareData(response, firstElementRef.current);

        setTokenCache({
          initial: {
            data: preparedData,
            nextToken: data?.ssStandards?.nextToken,
            prevToken: '',
          },
        });
        setCurrentToken('initial');
        searchParams.delete('standards-page');
        setSearchParams(searchParams);
        setPageLoading(false);
      },
      onError() {
        setPageLoading(false);
      },
    });
  }, [sorting, level, classFilter]);

  const currentPageStudents = tokenCache[currentToken];

  const { columnData, columns, total } = useMemo(() => {
    const standardToStudentMap = mapStandardToStudent(
      currentPageStudents?.data
    );

    const allStandards =
      standardToStudentMap?.map((item) => {
        const { studentId, studentUsername, studentName, ...rest } = item;

        const data = Object.keys(rest).reduce<
          { standard: string; description?: string }[]
        >((acc, val) => {
          return [
            ...acc,
            {
              standard: val,
              description: (rest[val] as StandardRecord).description,
            },
          ];
        }, []);
        return data;
      }) || [];

    const uniqueStandards = allStandards
      .flat()
      .reduce<{ standard: string; description?: string }[]>((acc, current) => {
        if (!acc.find((item) => item.standard === current.standard)) {
          acc.push(current);
        }
        return acc;
      }, []);

    const sortedStandards = sortStandardsAlphaNumerically(uniqueStandards);

    const paginatedStandards = sortedStandards.slice(
      (standardsPage - 1) * standardsPerPage,
      standardsPage * standardsPerPage
    );

    const columns = getColumns(paginatedStandards);
    return {
      columnData: standardToStudentMap,
      columns,
      total: sortedStandards.length,
    };
  }, [currentPageStudents, standardsPage]);

  const handleNextPage = () => {
    const nextToken = currentPageStudents?.nextToken;
    const prevToken = currentToken;

    if (nextToken) {
      if (tokenCache[nextToken]) {
        setCurrentToken(nextToken);
        return;
      }

      const order = getOrder(sorting);

      fetch({
        fetchPolicy: 'cache-and-network',
        variables: {
          schoolId,
          districtId,
          assignmentId,
          level,
          order,
          itemsPerPage,
          nextToken,
          teacherId,
        },
        onCompleted(data) {
          const response: ReponseObject = parseJSON(
            data?.ssStandards?.resultSetJson || '{}'
          );

          if (!response?.Rows?.length) {
            cacheTokenData(
              currentToken,
              currentPageStudents?.data,
              '',
              currentPageStudents?.prevToken
            );
            return;
          }

          const preparedData = prepareData(response, firstElementRef.current);
          cacheTokenData(
            nextToken,
            preparedData,
            data?.ssStandards?.nextToken,
            prevToken
          );
        },
      });
    }
  };

  const handlePreviousPage = () => {
    const prevToken = currentPageStudents?.prevToken;
    if (prevToken) {
      if (tokenCache[prevToken]) {
        setCurrentToken(prevToken);
        return;
      }
    }
  };

  const usersHaveReports = columnData?.some(
    (data) => Object.keys(data).length > 3
  );

  const isLoading = loading || pageLoading;

  return (
    <Flex direction="column" grow={1} height="100%">
      {isLoading ? (
        <Flex
          grow={1}
          direction="column"
          borderRadius="6px"
          backgroundColor="white"
        >
          <Flex justify="space-between" align="center" h="full">
            <Spinner mx="auto" color="primary.800" />
          </Flex>
        </Flex>
      ) : (
        <>
          <Table
            renderGroupActions={() => (
              <Caption total={total} itemsPerPage={standardsPerPage} />
            )}
            useDefaultSortingIcon={false}
            showGroupActionsIfEmpty
            // case when there are users but they do not have reports
            data={usersHaveReports ? columnData : []}
            columns={columns}
            serverSideSorting={{ sorting, setSorting }}
            containerProps={{
              boxShadow: 'none',
              borderBottom: 'none',
              borderRadius: '0.375rem 0.375rem 0 0',
            }}
          />
          <Pagination
            handlePrevPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            isDisabledPrev={!currentPageStudents?.prevToken || loading}
            isDisableNext={!currentPageStudents?.nextToken || loading}
          />
        </>
      )}
    </Flex>
  );
};

export default Report;
