import { TABLET_TAB_PANEL_PADDING } from '../../../../duck/constants';
import { AssignmentFormContext } from '../../../../duck/context';
import { TabProps } from '../../duck/types';
import { Box, Button, TabPanel, Text } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Icon, InputsList } from "@lon/shared/components";
import { useMediaQuery } from '@lon/shared/hooks';
import { ClassInput } from './components';

const Classes: React.FC<TabProps> = ({ classesOptions, classesLoading }) => {
  const { t } = useTranslation();
  const { withOtherAssignment, isTeacherAssessment } = useContext(
    AssignmentFormContext
  );
  const formContext = useFormContext();
  const selectedClasses = useWatch({ name: 'classes' })
  const [isTablet] = useMediaQuery('(max-width: 1024px)');

  const renderClassInput = useCallback(
    (props: any) => (
      <ClassInput
        classesOptions={classesOptions}
        classesLoading={classesLoading}
        {...props}
      />
    ),
    [classesOptions, classesLoading]
  );

  const addAllClasses = () => {
    formContext.setValue(
      'classes',
      classesOptions?.reduce<{classId: string}[]>(
        (acc, item) => {
          const alreadySelected = selectedClasses.find((i: { classId: string }) => i.classId === item.value)
          return [...acc, alreadySelected ? alreadySelected : {classId: item.value}]
        }, [])
      )
  };

  return (
    <TabPanel pb="4" {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}>
      <Box pb="4">
        <Text
          variant="s3"
          color="primary.400"
          whiteSpace="pre-wrap"
          textAlign="left"
          {...(!isTablet && { maxWidth: '700px' })}
        >
          {isTeacherAssessment
            ? t('createAssignmentDrawer.assessmentsClassesIntro')
            : t('createAssignmentDrawer.classesIntro')}
        </Text>
      </Box>
      <InputsList
        canBeEmpty
        withDivider={withOtherAssignment}
        name="classes"
        defaultValue={{ classId: '' }}
        mobile={isTablet}
        input={renderClassInput}
        addButtonTitle={t('createAssignmentDrawer.addClassBtn')}
        renderLabel={(index) =>
          t('createAssignmentDrawer.class', {
            count: index + 1,
          })
        }
        renderPlaceholder={() => t('createAssignmentDrawer.classPlaceholder')}
        isRemoveButtonDisabled={() => false}
        addBtnTooltip={
          isTeacherAssessment
            ? t('createAssignmentDrawer.addAssessmentsClassesBtnTooltip')
            : t('createAssignmentDrawer.addAssignmentsClassesBtnTooltip')
        }
        isDisabled={selectedClasses.length === classesOptions?.length}
        additionalButton={
          <Button
            variant="link"
            onClick={addAllClasses}
            leftIcon={<Icon name="wand-outlined" />}
            isDisabled={selectedClasses.length === classesOptions?.length}
          >
            {t('createAssignmentDrawer.addAllClasses')}
          </Button>
        }
        {...(isTablet
          ? {
              dividerStyles: {
                ml: `-${TABLET_TAB_PANEL_PADDING}`,
                w: `calc(100% + 2 * ${TABLET_TAB_PANEL_PADDING})`,
              },
            }
          : { maxWidth: '700px' })}
      />

    </TabPanel>
  );
};

export default Classes;
